import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from "../Images/logo.png";
import { NavLink } from "react-router-dom";
import logo1 from "../Images/logo.png";
// import logo1 from "../Images/icons/icon.png";
import "../Css/All.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container container-flex">
          <div className="copyright">
            <div className="logoContainerFooter">
              <p>All rights reserved &#174;</p>
              <NavLink to="/">
                <img src={logo1} alt="logo1" className="footerLogo" />
              </NavLink>
            </div>
          </div>

          <div className="footerContactMail">
            <p>Mailto:info@manognaa-solutions.com</p>
          </div>
          <div className="footerContactPh">
            <p>Phone:+919490746930</p>
          </div>

          <div className="footerIcons">
            {/* <FacebookIcon className="icon" /> */}
            <NavLink to="https://instagram.com/manognaasolutions?igshid=MzNlNGNkZWQ4Mg==">
              <InstagramIcon className="icon" />
            </NavLink>

            {/* <WhatsAppIcon className="icon" /> */}
            {/* <TwitterIcon className="icon" /> */}
            {/* <LinkedInIcon className="icon" /> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
