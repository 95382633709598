import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Component/Home";
import About from "./Component/About";
import Contact from "./Component/Contact";
import Services from "./Component/Services";
import ErrorPage from "./Component/ErrorPage";
import List from "./Component/List";
import Footer from "./Component/Footer";

const App = () => {
  return (
    <>
      <List />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </>
  );
};
export default App;
