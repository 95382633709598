import React from "react";
import logo from "../Images/logo.png";
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";
import "../Css/All.css";

const List = () => {
  return (
    <>
      <p>
        <header>
          <div className="container container-flex">
            <div className="logoContainerHeader">
              <NavLink to="/">
                <img src={logo} alt="logo" className="headerLogo" />
              </NavLink>
            </div>
            <nav>
              <div className="list">
                <NavLink
                  to="/"
                  className={(navdata) =>
                    navdata.isActive ? "activeItem" : "inactiveItem"
                  }
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  className={(navdata) =>
                    navdata.isActive ? "activeItem" : "inactiveItem"
                  }
                >
                  About
                </NavLink>
                <NavLink
                  to="/services"
                  className={(navdata) =>
                    navdata.isActive ? "activeItem" : "inactiveItem"
                  }
                >
                  Services
                </NavLink>
                <NavLink
                  to="/contact"
                  className={(navdata) =>
                    navdata.isActive ? "activeItem" : "inactiveItem"
                  }
                >
                  Contact
                </NavLink>
              </div>
            </nav>
            <div className="icons">
              <NavLink to="/services">
                <SearchIcon className="icon" />
              </NavLink>
              <NavLink to="/about">
                <PersonIcon className="icon" />
              </NavLink>
              <NavLink to="/contact">
                <CallIcon className="icon" />
              </NavLink>
            </div>
          </div>
        </header>
      </p>
    </>
  );
};

export default List;
