import React from "react";

const ErrorPage = () => {
  return (
    <>
      <h1>404...Page not found</h1>
    </>
  );
};

export default ErrorPage;
